import { faCopy, faEye, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { VoteRequest } from "../../api/api-interfaces/entry/entry-details/VoteRequest";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import CustomLink from "../../components/common/custom-link/CustomLink";
import ImageComponent from "../../components/common/image-component/ImageComponent";
import ImageGallery from "../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import LoginComponent from "../../components/common/login-component/LoginComponent";
import NotProvided from "../../components/common/not-provided-value/NotProvided";
import { CUSTOM_FIELD_CODES, KIWANIS_GOLD_COLOR, MOMENT_DATE_TIME_FORMAT } from "../../constants/Constants";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { whiteBorder } from "../../styles/ButtonStyles";
import { img, thumb, thumbInner, thumbsContainer } from "../../styles/ImageSmallStyles";
import styles from "./EntryDetails.module.scss";
import { IEntryDetailsProps } from "./IEntryDetailsProps";
import { IEntryDetailsState } from "./IEntryDetailsState";
import ContestOrEntryDoesNotExistError from "../../api/api-interfaces/errors/ContestOrEntryDoesNotExistError";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";
import { faVerticalAlign } from "../../styles/IconStyles";

class EntryDetails extends Component<IEntryDetailsProps, IEntryDetailsState> {
  listener: any;

  constructor(props: IEntryDetailsProps) {
    super(props);

    this.state = {
      contestId: 0,
      entryId: 0,
      sorting: '',
      title: '',
      description: '',
      viewsCount: 0,
      votesCount: 0,
      defaultImageUrl: "",
      imageUrls: [],
      fields: [],
      canUserVoteOnThis: false,
      hideVoteCount: false,
      isVotingStarted: false,
      isVotingOpen: false,
      isVotingEnded: false,
      isWinner: false,
      entryDetailsUrl: '',

      isImageGalleryOpen: false,
      indexOfImages: 0,

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],

      pageToRedirect: 1,
      redirect: null,

      userShouldLogin: false,
      isAnimationModeEnabled: false
    };
  }

  render() {
    let {defaultImageUrl, imageUrls, fields, entryDetailsUrl} = this.state;

    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect}/>;
    }

    let profileUrl = AuthHelper.isTokenSet()
      ? RoutingConstants.buildProfileUrl(this.state.contestId, this.state.pageToRedirect, this.state.sorting)
      : RoutingConstants.buildLoginRedirectUrl(RoutingConstants.buildProfileUrl(this.state.contestId, this.state.pageToRedirect, this.state.sorting));

    return (
      <ContainerPageSize>
        {
          this.state.userShouldLogin
            ? <LoginComponent onLoginSuccess={() => this.onLoginSuccess()}/>
            : <>
              {
                this.state.isLoading ? <LoadingBar/> :
                  <>
                    <div>
                      <Navbar bg="primary" variant="dark" id={'kva-entry-details'}>
                        <Container fluid>
                          <div className='d-flex align-items-center me-auto flex-wrap'>
                            <CustomLink
                              to={RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.pageToRedirect, this.state.sorting)}>
                              <IconButton styles={whiteBorder} variant="primary" iconType={"camera"} title={' Gallery'}/>
                            </CustomLink>

                            {
                              this.state.isVotingStarted && this.renderOnlyVotesOrButton()
                            }

                            <div>
                              {
                                this.state.isWinner &&
                                <div className='d-flex'>
                                  <FontAwesomeIcon icon={faTrophy}
                                                   color={KIWANIS_GOLD_COLOR}
                                                   size={"2x"}
                                                   className='align-self-center'
                                                   style={faVerticalAlign}
                                  />
                                </div>
                              }
                            </div>
                          </div>

                          <div>
                            <CustomLink to={profileUrl}>
                              <IconButton styles={whiteBorder} iconType={'user'} variant="primary" title={' Profile'}/>
                            </CustomLink>
                          </div>
                        </Container>
                      </Navbar>

                      <Row>
                        <Col md={6} lg={5} xl={4}>
                          <div className='my-2'>
                            <ImageComponent src={defaultImageUrl}
                                            onClick={() => this.setState({isImageGalleryOpen: true, indexOfImages: 0})}
                            />

                            {
                              imageUrls && imageUrls.length > 1 &&
                              <aside style={thumbsContainer} className='mt-2'>
                                {
                                  imageUrls.map((url, index) => {
                                    return (
                                      <div style={thumb} key={index}>
                                        <div style={thumbInner}>
                                          <img style={img} src={url} alt={'entry img'} className='cursor_pointer'
                                               onClick={() => this.setState({
                                                 isImageGalleryOpen: true,
                                                 indexOfImages: index
                                               })}/>
                                        </div>
                                      </div>
                                    );
                                  })
                                }
                              </aside>
                            }
                          </div>
                        </Col>
                      </Row>

                      <h5 style={{color: `${KIWANIS_GOLD_COLOR}`}}>{this.state.title}</h5>

                      <p style={{textAlign: 'justify'}} className={`text-break`}>{this.state.description}</p>

                      {
                        fields && fields.length > 0 &&
                        fields.map((field, index) => {
                          switch (field.fieldTypeCode) {
                            case CUSTOM_FIELD_CODES.Text:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {field.stringValue ? field.stringValue : <NotProvided/>}
                                  </div>
                                </div>
                              );
                            case CUSTOM_FIELD_CODES.TextArea:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {
                                      field.stringValue
                                        ? <span style={{whiteSpace: "pre-line"}}>{field.stringValue}</span>
                                        : <NotProvided/>
                                    }
                                  </div>
                                </div>
                              );
                            case CUSTOM_FIELD_CODES.RadioButton:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {field.stringValue ? field.stringValue : <NotProvided/>}
                                  </div>
                                </div>
                              );
                            case CUSTOM_FIELD_CODES.DropDownList:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {field.stringValue ? field.stringValue : <NotProvided/>}
                                  </div>
                                </div>
                              );
                            case CUSTOM_FIELD_CODES.Checkbox:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {
                                      field.boolValue === null
                                        ? <NotProvided/>
                                        : field.boolValue ? 'Yes' : 'No'
                                    }
                                  </div>
                                </div>
                              );
                            case CUSTOM_FIELD_CODES.DatePicker:
                              return (
                                <div key={index} className='my-3'>
                                  <div className={`text-break`}>
                                    <b>{field.name}</b>
                                  </div>
                                  <div className={`text-break`}>
                                    {
                                      field.dateTimeValue
                                        ? moment(field.dateTimeValue).format(MOMENT_DATE_TIME_FORMAT)
                                        : <NotProvided/>
                                    }
                                  </div>
                                </div>
                              );
                            default:
                              return (
                                <div>
                                  <span>Invalid field</span>
                                </div>
                              );
                          }
                        })
                      }

                      <ImageGallery
                        isOpen={this.state.isImageGalleryOpen}
                        indexOfImages={this.state.indexOfImages}
                        images={imageUrls}
                        onCloseRequest={() => this.setState({isImageGalleryOpen: false})}

                        onMovePrevRequest={() =>
                          this.setState({indexOfImages: (this.state.indexOfImages + imageUrls.length - 1) % imageUrls.length})
                        }

                        onMoveNextRequest={() =>
                          this.setState({indexOfImages: (this.state.indexOfImages + imageUrls.length + 1) % imageUrls.length})
                        }
                      />

                      <Navbar bg="primary" variant="dark" style={{minHeight: '50px'}}>
                        <Container fluid>
                          <div className='d-flex align-items-center me-auto flex-wrap'>
                            {
                              this.state.isVotingStarted &&
                              <div>
                                {
                                  this.renderOnlyVotesOrButton()
                                }
                              </div>
                            }
                            <div>
                              <span className='mx-2' style={{color: 'white'}}>
                                <FontAwesomeIcon icon={faEye} style={faVerticalAlign}/> {this.state.viewsCount}
                              </span>
                            </div>
                          </div>
                          <div className={styles.wrapper}>
                            <Button className={`${styles.edClipboardButton} mx-1`}
                                    onClick={() => this.onCopyCodeToClipboardClick(entryDetailsUrl)}>
                              <FontAwesomeIcon icon={faCopy} style={faVerticalAlign}/>
                            </Button>
                            <FacebookShareButton url={entryDetailsUrl}
                                                 title={this.state.title}
                                                 quote={this.state.title}
                                                 className="share"
                            >
                              <FacebookIcon size={32} round={true} className='mx-1'/>
                            </FacebookShareButton>
                            <TwitterShareButton url={entryDetailsUrl} title={this.state.title}>
                              <TwitterIcon size={32} round={true} className='mx-1'/>
                            </TwitterShareButton>
                          </div>
                        </Container>
                      </Navbar>
                    </div>
                  </>
              }
            </>
        }
      </ContainerPageSize>

    );
  }

  private renderOnlyVotesOrButton(): ReactNode {
    if (this.state.isVotingEnded || !this.state.isVotingOpen) {
      return this.state.hideVoteCount ? <span className='me-2'/> : this.renderOnlyVotes();
    } else {
      if (!AuthHelper.isTokenSet() || this.state.canUserVoteOnThis) {
        return this.renderButton();
      } else {
        return this.renderOnlyVotes();
      }
    }
  }

  private renderOnlyVotes() {
    return (
      <IconButton variant="primary"
                  iconType={"like"}
                  title={this.state.hideVoteCount ? '' : ` ${this.state.votesCount}`}
                  onClick={() => this.onVoteClick(this.state.entryId)}
                  className={styles.canNotVoteButton}
                  disabled={!this.state.canUserVoteOnThis}
      />
    );
  }

  private renderButton() {
    return (
      <IconButton styles={whiteBorder}
                  variant="primary"
                  iconType={"like"}
                  title={this.state.hideVoteCount ? '' : ` ${this.state.votesCount}`}
                  onClick={() => this.onVoteClick(this.state.entryId)}
                  className={this.state.isAnimationModeEnabled ? 'edVoteButton mx-2' : 'mx-2'}
      />);
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;
    let entryId = this.props.entryId;
    let sorting = this.props.sorting;

    this.setState({contestId: contestId, pageToRedirect: page, sorting: sorting});

    await this.getEntryDetails(entryId);

    this.scrollToEntryDetailsNavbar();

    this.listener = this.props.history.listen((location, action) => {
      if (!AuthHelper.isTokenSet() && this.state.userShouldLogin && action === 'POP') {
        this.setState({userShouldLogin: false});
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }


  private async getEntryDetails(entryId: number) {
    try {
      let response = await Api.getEntryDetailsPage(entryId, null);

      this.setState({
        entryId: entryId,
        title: response.title,
        description: response.description,
        viewsCount: response.viewsCount,
        votesCount: response.votesCount,
        hideVoteCount: response.hideVoteCount,
        defaultImageUrl: response.defaultImageUrl,
        imageUrls: response.imageUrls,
        fields: response.fields,
        canUserVoteOnThis: response.canUserVoteOnThis,
        isVotingStarted: response.isVotingStarted,
        isVotingOpen: response.isVotingOpen,
        isVotingEnded: response.isVotingEnded,
        isWinner: response.isWinner,
        entryDetailsUrl: response.entryDetailsUrl,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      if (err instanceof ContestOrEntryDoesNotExistError) {
        this.setState({redirect: RoutingConstants.buildEntryNotExistUrl(this.props.contestId, this.props.page, this.props.sorting)})
      } else {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t get entry details page from the server', err)
        );
      }
    }
  }

  private async onVoteClick(entryId: number) {
    if (!AuthHelper.isTokenSet()) {
      this.props.history.replace(RoutingConstants.buildEntryDetailsUrl(this.state.contestId, this.state.pageToRedirect, entryId, this.state.sorting));
      this.setState({userShouldLogin: true});
      return;
    }

    if (this.state.isAnimationModeEnabled) {
      return;
    }

    scrollToIdHelper(`kva-entry-details`);

    this.onShowAnimationClick();

    let request: VoteRequest = {
      contestEntryId: entryId
    };

    try {
      let response = await Api.voteFromEntryDetailsPage(request);

      this.setState({
        title: response.title,
        description: response.description,
        viewsCount: response.viewsCount,
        votesCount: response.votesCount,
        hideVoteCount: response.hideVoteCount,
        defaultImageUrl: response.defaultImageUrl,
        imageUrls: response.imageUrls,
        fields: response.fields,
        canUserVoteOnThis: response.canUserVoteOnThis,
        isVotingStarted: response.isVotingStarted,
        isVotingOpen: response.isVotingOpen,
        isVotingEnded: response.isVotingEnded,
        isWinner: response.isWinner,
        isLoading: false,
        validationErrors: {}
      });

      this.onHideAnimationClick();
    } catch (err) {
      this.onHideAnimationClick();
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t vote for an entry', err)
      );
    }
  }

  async onLoginSuccess() {
    let entryId = this.state.entryId;
    this.setState({userShouldLogin: false});

    await this.getEntryDetails(entryId);

    if (this.state.canUserVoteOnThis) {
      await this.onVoteClick(entryId);
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        toast.error(render);
      }
    }
  }

  async onCopyCodeToClipboardClick(entryDetailsUrl: string) {
    if ('clipboard' in navigator) {
      toast.success('URL was copied to a clipboard');
      return await navigator.clipboard.writeText(entryDetailsUrl ?? '');
    } else {
      toast.success('URL was copied to a clipboard');
      return document.execCommand('copy');
    }
  }

  onShowAnimationClick = () => {
    this.setState({isAnimationModeEnabled: true});
  };

  onHideAnimationClick = () => {
    setTimeout(() => {
      this.setState({isAnimationModeEnabled: false});
    }, 2000);
  };

  private scrollToEntryDetailsNavbar() {
    setTimeout(() => {
      scrollToIdHelper(`kva-entry-details`);
    }, 500);
  }
}

export default EntryDetails;
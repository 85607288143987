import React from "react";
import { Col, Row } from "react-bootstrap-v5";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";

const Home: React.FC = () => {
  return (
    <ContainerPageSize>
      <VerticalSpace size={VerticalSpaceSize.large}/>
      <div className="text-center mt-5">
        <Row>
          <Col md={{span: 6, offset: 3}}>
            <h1 className={"headline"}>Welcome to Kiwanis Voting App</h1>
          </Col>
        </Row>
      </div>
    </ContainerPageSize>
  );
};

export default Home;
import React from "react";
import { HashRouter } from "react-router-dom";
import App from './App';
import {StartingViewName} from "./StartingViewName";

interface IAppBrowserState{
  pageBaseName: string | undefined
}

interface IAppBrowserProps{
  pageBaseName: string | undefined,
  startingView: StartingViewName,
  contestId?: number | null
}

export default class AppBrowser extends React.Component<IAppBrowserProps, IAppBrowserState>{
  constructor(props: IAppBrowserProps){
    super(props);

    let pageBaseName = props.pageBaseName === null ? undefined : props.pageBaseName;

    if (pageBaseName !== undefined && pageBaseName.endsWith('/')){
      pageBaseName = pageBaseName.substring(0, pageBaseName.length - 2);
    }
  
    console.log(`Router baseName: ${pageBaseName}`);


    this.state = {
      pageBaseName: pageBaseName,
    };
  }

  render(): React.ReactNode {
    return (
      <HashRouter basename={this.state.pageBaseName} hashType={"noslash"}>
        <App startingView={this.props.startingView} contestId={this.props.contestId}/>
      </HashRouter>
    );
  }
}





import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../components/common/vertical-space/VerticalSpace";
import FieldNameHelper from "../../../../helpers/field-name-helper/FieldNameHelper";
import { IDropdownFieldProps } from "./IDropdownFieldProps";
import { IDropdownFieldState } from "./IDropdownFieldState";

const DEFAULT: string = "DEFAULT";

class DropdownField extends Component<IDropdownFieldProps, IDropdownFieldState> {

  constructor(props: IDropdownFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const dropdownFieldName = FieldNameHelper.getDropdownFieldName(index);
    const stringValueFieldName = FieldNameHelper.getStringValueFieldName(index);
    const fieldValuesStringValueFieldName = FieldNameHelper.getFieldValuesStringValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Label className='text-break'>
              {field.name}
              {field.isRequired ? <span className="required-asterisk">*</span> : null}
            </Form.Label>
            <Form.Control as='select' className='form-select text-truncate'
                          name={dropdownFieldName}
                          placeholder={`Please enter ${field.name}`}
                          value={field.value ? field.value : DEFAULT}
                          onChange={this.onDropdownFieldChange.bind(this)}
            >
              <option value={DEFAULT}>-- Please select --</option>
              {
                field.options?.map((option, index) => {
                  return <option key={'dr_' + field.id + index} value={option} title={option}>
                    {this.truncateOption(option)}
                  </option>;
                })
              }
            </Form.Control>
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={dropdownFieldName} errors={errors}/>
            <ValidationMessages fieldName={stringValueFieldName} errors={errors}/>
            <ValidationMessages fieldName={fieldValuesStringValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onDropdownFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onDropdownFieldChange) {
      this.props.onDropdownFieldChange(e.target.value);
    }
  }

  truncateOption(str: string) {
    return str.length > 100 ? str.substring(0, 99) + "..." : str;
  }
}

export default DropdownField;
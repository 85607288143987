import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../components/common/vertical-space/VerticalSpace";
import FieldNameHelper from "../../../../helpers/field-name-helper/FieldNameHelper";
import { ICheckboxFieldProps } from "./ICheckboxFieldProps";
import { ICheckboxFieldState } from "./ICheckboxFieldState";

class CheckboxField extends Component<ICheckboxFieldProps, ICheckboxFieldState> {

  constructor(props: ICheckboxFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const checkboxFieldName = FieldNameHelper.getCheckboxFieldName(index);
    const boolValueFieldName = FieldNameHelper.getBoolValueFieldName(index);
    const fieldValuesBoolValueFieldName = FieldNameHelper.getFieldValuesBoolValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Check type='checkbox' id={checkboxFieldName}
                        name={checkboxFieldName}
                        checked={field.value ? field.value : false}
                        onChange={this.onCheckboxChange.bind(this)}
                        label={(
                          <Form.Label className='mb-0 text-break'>
                            {field.name}
                            {field.isRequired ? <span className="required-asterisk">*</span> : null}
                          </Form.Label>
                        )}
            />
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={checkboxFieldName} errors={errors}/>
            <ValidationMessages fieldName={boolValueFieldName} errors={errors}/>
            <ValidationMessages fieldName={fieldValuesBoolValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onCheckboxFieldChange) {
      this.props.onCheckboxFieldChange(e.target.checked);
    }
  }
}

export default CheckboxField;
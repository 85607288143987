import Axios, { AxiosInstance } from "axios";
import urljoin from "url-join";
import RoutingConstants from "../routes/RoutingConstants";
import { IGetEntryDetailsPageResponse } from "./api-interfaces/entry/entry-details/IGetEntryDetailsPageResponse";
import { VoteRequest } from "./api-interfaces/entry/entry-details/VoteRequest";
import { VoteResponse } from "./api-interfaces/entry/entry-details/VoteResponse";
import { IGetContestDetailsResponse } from "./api-interfaces/entry/entry-list/IGetContestDetailsResponse";
import { IVoteRequest } from "./api-interfaces/entry/entry-list/IVoteRequest";
import { IVoteResponse } from "./api-interfaces/entry/entry-list/IVoteResponse";
import {
  IGetDataForEntryCreationResponse
} from "./api-interfaces/entry/add-or-edit-entry/IGetDataForEntryCreationResponse";
import {
  IGetDataForEntryEditingResponse
} from "./api-interfaces/entry/add-or-edit-entry/IGetDataForEntryEditingResponse";
import { IGetEntriesPageResponse } from "./api-interfaces/entry/IGetEntriesPageResponse";
import { IGetEntriesRequest } from "./api-interfaces/entry/IGetEntriesRequest";
import { ISaveEntryRequest } from "./api-interfaces/entry/add-or-edit-entry/ISaveEntryRequest";
import { ISaveEntryResponse } from "./api-interfaces/entry/add-or-edit-entry/ISaveEntryResponse";
import { IDeleteRequest } from "./api-interfaces/entry/submitted-entries-list/IDeleteRequest";
import {
  IGetDetailsForEditingResponse
} from "./api-interfaces/entry/edit-entry-after-win/IGetDetailsForEditingResponse";
import { IListRequest } from "./api-interfaces/entry/submitted-entries-list/IListRequest";
import { IListResponse } from "./api-interfaces/entry/submitted-entries-list/IListResponse";
import { ISaveRequest } from "./api-interfaces/entry/edit-entry-after-win/ISaveRequest";
import ApiCommunicationError from "./api-interfaces/errors/ApiCommunicationError";
import BadRequestError from "./api-interfaces/errors/BadRequestError";
import NotFoundError from "./api-interfaces/errors/NotFoundError";
import { IGetSignInDetailsResponse } from "./api-interfaces/login/IGetSignInDetailsResponse";
import { IGetTokenRequest } from "./api-interfaces/login/IGetTokenRequest";
import { IGetTokenResponse } from "./api-interfaces/login/IGetTokenResponse";
import { ISendSignInCodeRequest } from "./api-interfaces/login/ISendSignInCodeRequest";
import { IGetUserProfileDetailsResponse } from "./api-interfaces/profile/IGetUserProfileDetailsResponse";
import { ISetUserProfileDetailsRequest } from "./api-interfaces/profile/ISetUserProfileDetailsRequest";
import Settings from "../settings";
import ContestOrEntryDoesNotExistError from "./api-interfaces/errors/ContestOrEntryDoesNotExistError";
import AuthHelper from "../helpers/auth-helper/AuthHelper";
import { IGetContestsPageRequest } from "./api-interfaces/contest/contest-list/IGetContestsPageRequest";
import { IGetContestsPageResponse } from "./api-interfaces/contest/contest-list/IGetContestsPageResponse";

export default class Api {
  public static async getSignInDetails(): Promise<IGetSignInDetailsResponse> {
    let url = Api.buildUrl('/api/Login/GetSignInDetails');

    try {
      let response = await Axios.get<IGetSignInDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get get sign in details');
    }
  }

  public static async sendSignInCode(data: ISendSignInCodeRequest): Promise<void> {
    let url = Api.buildUrl('/api/Login/SendSignInCode');

    try {
      await Axios.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t send sign in code');
    }
  }

  public static async getToken(data: IGetTokenRequest): Promise<IGetTokenResponse> {
    let url = Api.buildUrl('/api/Login/GetToken');

    try {
      let response = await Axios.post<IGetTokenResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get authentication token');
    }
  }

  public static async getContestDetails(contestId: number): Promise<IGetContestDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryList/GetContestDetails/${contestId}`);

    try {
      let response = await client.get<IGetContestDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest details');
    }
  }

  public static async getContestsPage(data: IGetContestsPageRequest): Promise<IGetContestsPageResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestList/GetContestsPage');

    try {
      let response = await client.post<IGetContestsPageResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of contests');
    }
  }

  public static async getPastContestsPage(data: IGetContestsPageRequest): Promise<IGetContestsPageResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/PastContestList/GetPastContestsPage');

    try {
      let response = await client.post<IGetContestsPageResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of past contests');
    }
  }

  public static async getEntriesPage(data: IGetEntriesRequest): Promise<IGetEntriesPageResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/GetEntriesPage');

    try {
      let response = await client.post<IGetEntriesPageResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of entries');
    }
  }

  public static async voteForAnEntry(data: IVoteRequest): Promise<IVoteResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/Vote');

    try {
      let response = await client.post<IVoteResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t vote for an entry');
    }
  }

  public static async getEntryDetailsPage(entryId: number, shouldIncrementViewCount: boolean | null): Promise<IGetEntryDetailsPageResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let queryParameter: string;
    if (shouldIncrementViewCount == null) {
      queryParameter = '';
    } else if (shouldIncrementViewCount === true) {
      queryParameter = '?shouldIncrementViewCount=true';
    } else {
      queryParameter = '?shouldIncrementViewCount=false';
    }

    let url = Api.buildUrl(`/api/ContestEntryDetails/GetEntryDetailsPage/${entryId}${queryParameter}`);

    try {
      let response = await client.get<IGetEntryDetailsPageResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get entry details page');
    }
  }

  public static async voteFromEntryDetailsPage(data: VoteRequest): Promise<VoteResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryDetails/Vote');

    try {
      let response = await client.post<VoteResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t vote for an entry');
    }
  }

  public static async getDataForEntryCreation(contestId: number): Promise<IGetDataForEntryCreationResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntrySave/GetDataForEntryCreation/${contestId}`);

    try {
      let response = await client.get<IGetDataForEntryCreationResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get data for entry creation');
    }
  }

  public static async getDetailsForEditing(entryId: number): Promise<IGetDataForEntryEditingResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntrySave/GetDataForEntryEditing/${entryId}`);

    try {
      let response = await client.get<IGetDataForEntryEditingResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get details for editing');
    }
  }

  public static async saveContestEntry(data: ISaveEntryRequest): Promise<ISaveEntryResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntrySave/SaveEntry');

    try {
      let response = await client.post<ISaveEntryResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save contest entry');
    }
  }

  public static async getUserProfileDetails(): Promise<IGetUserProfileDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/UserProfile/GetUserProfileDetails`);

    try {
      let response = await client.get<IGetUserProfileDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get user details');
    }
  }

  public static async setUserProfileDetails(data: ISetUserProfileDetailsRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/UserProfile/SetUserProfileDetails');

    try {
      let response = await client.post<void>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t update user details');
    }
  }

  public static async getListOfSubmittedEntries(data: IListRequest): Promise<IListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/SubmittedEntriesList/List');

    try {
      let response = await client.post<IListResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of submitted entries');
    }
  }

  public static async getDetailsForEditingAfterWin(entryId: number): Promise<IGetDetailsForEditingResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryEditAfterWin/GetDetailsForEditing/${entryId}`);

    try {
      let response = await client.get<IGetDetailsForEditingResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get data for entry editing');
    }
  }

  public static async saveEditedEntryAfterWin(data: ISaveRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryEditAfterWin/Save');

    try {
      await client.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save entry');
    }
  }

  public static async deleteEntry(data: IDeleteRequest): Promise<IListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/SubmittedEntriesList/Delete`);

    try {
      let response = await client.post<IListResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete entry');
    }
  }

  private static buildCommunicationException(err: any, generalErrorMessage: string): Error {
    if (err.response) {
      if (err.response.status === 400) {
        let content = err.response.data;
        throw new BadRequestError(content);
      }
      if (err.response.status === 409) {
        throw new ContestOrEntryDoesNotExistError();
      }
      if (window !== undefined) {
        if (err.response.status === 401) {
          AuthHelper.removeSession();
          window.location.pathname = RoutingConstants.buildLoginRedirectUrl(null);
        }
        if (err.response.status === 403) {
          window.location.pathname = RoutingConstants.buildNoPermittedUrl();
        }
      }
      if (err.response.status === 404) {
        throw new NotFoundError(err);
      }
    }
    throw new ApiCommunicationError(generalErrorMessage, err);
  }

  public static buildUrl(relativePath: string): string {
    const apiUrl = Settings.API_PUBLIC_URL;
    return urljoin(apiUrl, relativePath);
  }

  private static getAuthenticatedAxiosClient(): AxiosInstance {
    let accessToken = AuthHelper.getAccessToken() ?? '';

    return Axios.create({
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json'
      },
    });
  }
}
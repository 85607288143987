import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import ProfileComponent from "../../components/common/profile-component/ProfileComponent";
import RoutingConstants from "../../routes/RoutingConstants";
import { IProfilePageProps } from "./IProfilePageProps";
import { IProfilePageState } from "./IProfilePageState";

class ProfilePage extends Component<IProfilePageProps, IProfilePageState> {
  constructor(props: IProfilePageProps) {
    super(props);

    this.state = {
      contestId: 0,
      page: 1,
      sorting: '',
      redirect: null,
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <ContainerPageSize>
        <ProfileComponent contestId={this.props.contestId}
                          page={this.props.page}
                          sorting={this.props.sorting}
                          onProfileSaveSuccess={() => this.onProfileSaveSuccess()}
                          onProfileCancelClick={() => this.onProfileCancelClick()}
        />
      </ContainerPageSize>
    );
  }

  componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;
    let sorting = this.props.sorting;

    this.setState({page: page, contestId: contestId, sorting: sorting});
  }

  private onProfileSaveSuccess() {
    let state = {...this.state};
    state.redirect = RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting);
    this.setState(state);
  }

  private onProfileCancelClick() {
    let state = {...this.state};
    state.redirect = RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting);
    this.setState(state);
  }
}

export default withVotingAppAuthorize(ProfilePage);
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import styles from '../../../styles/ModalWindow.module.scss';
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import { ISubmitEntryNotificationModalProps } from "./ISubmitEntryNotificationModalProps";
import { ISubmitEntryNotificationModalState } from "./ISubmitEntryNotificationModalState";
import HtmlHelper from "../../../helpers/html-helper/HtmlHelper";

class SubmitEntryNotificationModal extends Component<ISubmitEntryNotificationModalProps, ISubmitEntryNotificationModalState> {

  private static dialogIndex = 100;

  constructor(props: ISubmitEntryNotificationModalProps) {
    super(props);

    this.state = {
      dialogId: 'submit_entry_dialog_' + (SubmitEntryNotificationModal.dialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    const rootDivElement = HtmlHelper.getRootDivElement()

    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content}`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={rootDivElement}
                  parentSelector={() => rootDivElement}
                  ariaHideApp={false}
      >

        <div className="kiwanis-voting-app">
          <h2>Submit entry</h2>

          <p>
            Your contest entry has been submitted for review. <br/>
            Entry will show up on voting page once it's approved for voting.
          </p>

          <div className="d-flex justify-content-end">
            <IconButton variant="outline-primary" title="OK" className='px-4'
                        onClick={this.onConfirmClicked}
            />
          </div>
        </div>

      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }
}

export default SubmitEntryNotificationModal;
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IToastProps } from "./IToastProps";

const Toast: React.FC<IToastProps> = (props) => {
  let {position, autoClose} = props;

  return (
    <div>
      <ToastContainer position={position}
                      autoClose={autoClose}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      limit={2}
      />
    </div>
  );
};

export default Toast;
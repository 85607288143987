import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Col, Container, Form, Navbar, Row } from "react-bootstrap-v5";
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { IVoteRequest } from "../../api/api-interfaces/entry/entry-list/IVoteRequest";
import { IEntryTileInfo } from "../../api/api-interfaces/entry/IEntryTileInfo";
import { IGetEntriesRequest } from "../../api/api-interfaces/entry/IGetEntriesRequest";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import CustomLink from "../../components/common/custom-link/CustomLink";
import ImageGallery from "../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import LoginComponent from "../../components/common/login-component/LoginComponent";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import CountdownTimer from "../../components/countdown-timer/CountdownTimer";
import EntryCard from "../../components/entry-card/EntryCard";
import { ENTRY_SORTING, ENTRY_SORTING_OPTIONS } from "../../constants/Constants";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { whiteBorder } from "../../styles/ButtonStyles";
import './EntryList.scss';
import { IEntryListProps } from "./IEntryListProps";
import { IEntryListState } from "./IEntryListState";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";
import ContestOrEntryDoesNotExistError from "../../api/api-interfaces/errors/ContestOrEntryDoesNotExistError";

class EntryList extends Component<IEntryListProps, IEntryListState> {
  interval: any;
  listener: any;

  constructor(props: IEntryListProps) {
    super(props);

    this.state = {
      contestId: 0,
      contestName: '',
      contestDescription: '',

      isVotingStarted: false,
      isVotingOpen: false,
      isVotingEnded: null,
      votingEndTime: null,
      isEntrySubmitOpen: false,
      hideVoteCount: false,
      showTotalVoteCount: false,
      totalVotes: 0,
      rulesAndPrizesHtml: '',

      entries: null,
      page: 1,
      totalPages: 1,
      itemsPerPage: 24,

      isSortingChanged: false,
      isPageClick: false,

      isAnimationModeEnabled: false,

      isOpen: false,
      indexOfImages: 0,
      entryImages: [],

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
      redirect: null,

      userShouldLogin: false,
      entryIdToVote: null,
      indexToVote: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      const redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    let {entryImages} = this.state;

    let addOrEditEntryUrl = AuthHelper.isTokenSet()
      ? RoutingConstants.buildEntryCreateUrl(this.state.contestId, this.state.page, this.props.sorting ?? ENTRY_SORTING.Random)
      : RoutingConstants.buildLoginRedirectUrl(RoutingConstants.buildEntryCreateUrl(this.state.contestId, this.state.page, this.props.sorting ?? ENTRY_SORTING.Random));

    let profileUrl = AuthHelper.isTokenSet()
      ? RoutingConstants.buildProfileUrl(this.state.contestId, this.state.page, this.props.sorting ?? ENTRY_SORTING.Random)
      : RoutingConstants.buildLoginRedirectUrl(RoutingConstants.buildProfileUrl(this.state.contestId, this.state.page, this.props.sorting ?? ENTRY_SORTING.Random));

    return (
      <ContainerPageSize>
        {
          this.state.userShouldLogin
            ? <LoginComponent onLoginSuccess={() => this.onLoginSuccess()}/>
            : <>
              {
                this.state.isLoading ? <LoadingBar/> :
                  <>
                    <div>
                      <Row>
                        <Col>
                          <CountdownTimer isVotingOpen={this.state.isVotingOpen}
                                          isVotingEnded={this.state.isVotingEnded === null ? false : this.state.isVotingEnded}
                                          date={this.state.votingEndTime}
                          />
                        </Col>
                      </Row>
                      <VerticalSpace size={VerticalSpaceSize.small}/>

                      <Navbar bg='primary' variant='dark' className='d-none d-lg-block kva-navbar' id={"kva-entry-list"}>
                        <Container fluid>
                          <div className="d-flex me-auto">
                            {
                              this.state.showTotalVoteCount &&
                              <div className='mx-3 d-flex align-items-center' style={{color: "white"}}>
                                Total votes {this.state.totalVotes}
                              </div>
                            }

                            <div>
                              {
                                this.state.isEntrySubmitOpen &&
                                <CustomLink to={addOrEditEntryUrl}>
                                  <IconButton styles={{border: 'white 1px solid'}}
                                              iconType={'add'}
                                              variant="primary"
                                              title={' Submit entry'}
                                  />
                                </CustomLink>
                              }
                            </div>
                          </div>

                          <div style={{color: "white"}}>Sorting</div>
                          <div className='col-3 mx-md-3 my-3 my-md-0 d-inline-block'>
                            <Form.Control as='select' className='form-select'
                                          value={this.props.sorting ?? ENTRY_SORTING.Random}
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => this.onSortingChange(e)}>
                              {
                                ENTRY_SORTING_OPTIONS.map((x) => {
                                  return <option key={x.code} value={x.code}>{x.name}</option>;
                                })
                              }
                            </Form.Control>
                          </div>

                          <div>
                            <CustomLink to={profileUrl}>
                              <IconButton styles={whiteBorder} iconType={'user'} variant="primary" title={' Profile'}/>
                            </CustomLink>
                          </div>
                        </Container>
                      </Navbar>

                      <Navbar bg='primary' variant='dark' className='d-block d-lg-none kva-navbar'>
                        <div className="d-flex me-auto flex-wrap">
                          {
                            this.state.showTotalVoteCount &&
                            <div className='mx-3 py-1 d-flex align-items-center' style={{color: "white"}}>
                              Total votes {this.state.totalVotes}
                            </div>
                          }
                        </div>

                        <div className='mx-3 py-2 d-flex d-block'>
                          {
                            this.state.isEntrySubmitOpen &&
                            <CustomLink to={addOrEditEntryUrl}>
                              <IconButton styles={{border: 'white 1px solid'}}
                                          iconType={'add'}
                                          variant="primary"
                                          title={' Submit entry'}
                              />
                            </CustomLink>
                          }

                          <div className={this.state.isEntrySubmitOpen ? 'mx-3' : ''}>
                            <CustomLink to={profileUrl}>
                              <IconButton styles={whiteBorder} iconType={'user'} variant="primary" title={' Profile'}/>
                            </CustomLink>
                          </div>
                        </div>

                        <div className="d-flex me-auto mx-3 py-1 align-items-center">
                          <div style={{color: "white"}}>Sorting</div>

                          <Form.Control as='select' className='form-select mx-2' style={{width: "70%"}}
                                        value={this.props.sorting ?? ENTRY_SORTING.Random}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.onSortingChange(e)}>
                            {
                              ENTRY_SORTING_OPTIONS.map((x) => {
                                return <option key={x.code} value={x.code}>{x.name}</option>;
                              })
                            }
                          </Form.Control>

                        </div>
                      </Navbar>

                      <div id={"kva-navbar"}/>
                      <Row className="tilesList">
                        {
                          this.state.entries && this.state.entries.length > 0
                            ? this.state.entries.map((entry, index) => {
                              return (
                                <div key={entry.id} className="col-md-6 col-lg-4 col-xl-3 col-xxl-2 gy-4">
                                  <EntryCard entry={entry}
                                             contestId={this.state.contestId}
                                             isVotingStarted={this.state.isVotingStarted}
                                             isVotingOpen={this.state.isVotingOpen}
                                             votingEndTime={this.state.votingEndTime}
                                             isVotingEnded={this.state.isVotingEnded === null ? true : this.state.isVotingEnded}
                                             hideVoteCount={this.state.hideVoteCount}
                                             onEntryDetailsClick={(redirectUrl) => this.onEntryDetailsClick(redirectUrl)}
                                             onCopyCodeToClipboardClick={(entryDetailsUrl) => this.onCopyCodeToClipboardClick(entryDetailsUrl)}
                                             onImageGalleryOpenClick={this.onImageGalleryOpenClick.bind(this)}
                                             onVoteClick={() => this.onVoteClick(entry.id, index)}
                                             page={this.state.page}
                                             sorting={this.props.sorting ?? ENTRY_SORTING.Random}
                                             buttonScrollId={`entry_${entry.id}`}
                                  />
                                </div>
                              );
                            })
                            : <div className='text-center m-5'>No entries</div>
                        }
                      </Row>

                      <ImageGallery
                        isOpen={this.state.isOpen}
                        indexOfImages={this.state.indexOfImages}
                        images={entryImages}
                        onCloseRequest={() => this.setState({isOpen: false, entryImages: [], indexOfImages: 0})}

                        onMovePrevRequest={() =>
                          this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length - 1) % entryImages.length})
                        }

                        onMoveNextRequest={() =>
                          this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length + 1) % entryImages.length})
                        }
                      />

                      {
                        this.state.totalPages > 1 &&
                        <Row id='kva-pager'>
                          <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                            <Pagination {...bootstrap5PaginationPreset}
                                        current={this.state.page}
                                        total={this.state.totalPages}
                                        onPageChange={(page: number) => this.onEntryPageChange(page)}
                            />
                          </Col>
                        </Row>
                      }
                    </div>
                  </>
              }
            </>
        }
      </ContainerPageSize>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;

    let state = {...this.state};
    state.contestId = contestId;
    state.page = page;
    this.setState(state);

    await this.getContestDetails(contestId);

    let sorting = this.props.sorting === null
      ? this.state.isVotingEnded === false
        ? ENTRY_SORTING.Random
        : ENTRY_SORTING.VotesDescending
      : this.props.sorting;

    if (this.props.sorting === null) {
      this.setState({
        redirect: RoutingConstants.buildEntryListUrl(this.state.contestId, page, sorting)
      })
    } else {
      await this.getContestEntryList(contestId, this.props.sorting, page);
    }

    this.interval = setInterval(async () => {
      if (!this.state.isAnimationModeEnabled) {
        await this.getContestDetails(contestId);
        await this.getContestEntryList(contestId, this.props.sorting ?? ENTRY_SORTING.Random, this.state.page);
      }
    }, 10000);

    this.listener = this.props.history.listen((location, action) => {
      if (!AuthHelper.isTokenSet() && this.state.userShouldLogin && action === 'POP') {
        this.setState({userShouldLogin: false, entryIdToVote: null, indexToVote: null});
      }

      if (action === "PUSH" && this.state.isPageClick) {
        this.scrollToPager();
        this.setState({isPageClick: false});
      }

      if (action === "PUSH" && this.state.isVotingEnded && !this.state.isPageClick) {
        this.scrollToNavbar();
        this.setState({isPageClick: false, isSortingChanged: false});
      }
    });
  }

  async componentDidUpdate(prevProps: Readonly<IEntryListProps>) {
    if (this.props.page !== prevProps.page || this.props.sorting !== prevProps.sorting) {

      let state = {...this.state};
      state.isLoading = true;
      state.page = this.props.page;
      state.redirect = null;
      this.setState(state);

      await this.getContestEntryList(this.state.contestId, this.props.sorting ?? ENTRY_SORTING.Random, this.props.page);
    }
  }


  componentWillUnmount() {
    clearInterval(this.interval);
    this.listener();
  }


  private async getContestDetails(contestId: number) {
    try {
      let response = await Api.getContestDetails(contestId);

      let previousIsVotingEnded = this.state.isVotingEnded;
      let redirect = previousIsVotingEnded === false && response.isVotingEnded
        ? RoutingConstants.buildEntryListUrl(this.state.contestId, 1, ENTRY_SORTING.VotesDescending)
        : null;

      this.setState({
        contestId: contestId,
        contestName: response.contestName,
        contestDescription: response.contestDescription,
        isVotingStarted: response.isVotingStarted,
        isVotingOpen: response.isVotingOpen,
        isVotingEnded: response.isVotingEnded,
        votingEndTime: response.votingEndTime ? moment.utc(response.votingEndTime).toDate() : null,
        isEntrySubmitOpen: response.isEntrySubmitOpen,
        hideVoteCount: response.hideVoteCount,
        showTotalVoteCount: response.showTotalVoteCount,
        totalVotes: response.totalVotes,
        rulesAndPrizesHtml: response.rulesAndPrizesHtml,
        validationErrors: {},
        redirect: redirect
      });
    } catch (err) {
      if (err instanceof ContestOrEntryDoesNotExistError) {
        this.setState({redirect: RoutingConstants.CONTEST_DOES_NOT_EXIST})
      } else {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t get contest details from the server', err)
        );
      }
    }
  }


  private async getContestEntryList(contestId: number, sorting: string, page?: number) {
    let request: IGetEntriesRequest = {
      contestId: contestId,
      itemsPerPage: this.state.itemsPerPage,
      page: page ? page : this.state.page,
      sorting: sorting,
    };

    try {
      let response = await Api.getEntriesPage(request);

      let state = {...this.state};
      state.contestId = contestId;
      state.entries = response.entries;
      state.page = response.pager.page;
      state.totalPages = response.pager.totalPages;
      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      if (err instanceof ContestOrEntryDoesNotExistError) {
        this.setState({redirect: RoutingConstants.CONTEST_DOES_NOT_EXIST})
      } else {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t get entry list from the server', err)
        );
      }
    }
  }

  private async onSortingChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      isLoading: true,
      isSortingChanged: true,
      redirect: RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, event.target.value)
    });
  }


  private onEntryDetailsClick(redirectUrl: string) {
    this.setState({redirect: redirectUrl});
  }


  async onCopyCodeToClipboardClick(entryDetailsUrl: string) {
    if ('clipboard' in navigator) {
      toast.success('URL was copied to a clipboard');
      return await navigator.clipboard.writeText(entryDetailsUrl ?? '');
    } else {
      toast.success('URL was copied to a clipboard');
      return document.execCommand('copy');
    }
  }


  private onImageGalleryOpenClick(entryImages: string[]) {
    this.setState({isOpen: true, entryImages: entryImages});
  }


  private async onVoteClick(entryId: number, index: number) {
    this.setState({isAnimationModeEnabled: true});

    if (!AuthHelper.isTokenSet()) {
      this.props.history.push(RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.props.sorting ?? ENTRY_SORTING.Random));
      this.setState({userShouldLogin: true, entryIdToVote: entryId, indexToVote: index});
      return;
    }

    let entries = JSON.parse(JSON.stringify(this.state.entries));
    if (entries && entries.length > 0) {
      entries[index].showAnimation = true;
      this.setState({entries: entries});
    }

    let request: IVoteRequest = {
      contestEntryId: entryId,
      itemsPerPage: this.state.itemsPerPage,
      page: this.state.page,
      sorting: this.props.sorting ?? ENTRY_SORTING.Random
    };

    try {
      let response = await Api.voteForAnEntry(request);

      setTimeout(() => {
        let state = {...this.state};
        state.isAnimationModeEnabled = false;
        state.entries = response.entries;
        state.page = response.pager.page;
        state.totalPages = response.pager.totalPages;
        state.isLoading = false;
        this.setState(state);
      }, 2000);
    } catch (err) {
      if (err instanceof ContestOrEntryDoesNotExistError) {
        this.setState({redirect: RoutingConstants.CONTEST_DOES_NOT_EXIST})
      } else {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t vote for an entry on the server', err)
        );
      }
    }
  }


  private async onEntryPageChange(page: number) {
    if (this.state.page !== page) {
      let state = {...this.state};
      state.page = page;
      state.isPageClick = true;
      state.redirect = RoutingConstants.buildEntryListUrl(this.state.contestId, page, this.props.sorting ?? ENTRY_SORTING.Random);
      this.setState(state);
    }
  }


  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    state.isAnimationModeEnabled = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        const customId = "custom-id";

        toast.error(render, {toastId: customId});
      }
    }
  }


  async onLoginSuccess() {
    this.setState({userShouldLogin: false, isLoading: true});

    await this.getContestEntryList(this.state.contestId, this.props.sorting ?? ENTRY_SORTING.Random, this.state.page);

    let entryIdToVote = this.state.entryIdToVote;
    let indexToVote = this.state.indexToVote;
    let entries = this.state.entries;

    if (entryIdToVote && indexToVote !== null) {
      if (entries && entries.length > 0) {
        let entry = this.findEntryById(entries, entryIdToVote);

        if (entry && entry.canUserVoteOnThis) {
          await this.onVoteClick(entryIdToVote, indexToVote);
          this.scrollToEntry(entryIdToVote);
        } else {
          this.scrollToEntry(entryIdToVote);
        }
      }
    }

    this.setState({entryIdToVote: null, indexToVote: null});
  }


  private scrollToPager() {
    setTimeout(() => {
      scrollToIdHelper(`kva-pager`);
    }, 1000);
  }

  private scrollToNavbar() {
    console.log("scrollToNavbar")
    setTimeout(() => {
      scrollToIdHelper(`kva-navbar`);
    }, 1000);
  }


  private scrollToEntry(entryIdToVote: number | null) {
    setTimeout(() => {
      if (entryIdToVote) {
        scrollToIdHelper(`entry_${entryIdToVote}`);
      }
    }, 1000);
  }


  findEntryById(entries: IEntryTileInfo[], entryId: number) {
    return entries.find((entry) => {
      return entry.id === entryId;
    });
  }
}

export default EntryList;
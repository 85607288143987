import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.browser';
import reportWebVitals from './reportWebVitals';
import {StartingViewName} from "./StartingViewName";

const rootElement = document.getElementById('voting-app-public-ui');
const pageBaseNameAttribute = rootElement === null ? null : rootElement.getAttribute("data-page-location");
const startingViewAttribute = rootElement === null ? null : rootElement.getAttribute("data-starting-view");
const contestIdAttribute = rootElement === null ? null : rootElement.getAttribute("data-contest-id");

let pageBaseName = pageBaseNameAttribute === null ? undefined : pageBaseNameAttribute;
let startingView : StartingViewName;
switch (startingViewAttribute){
    case "contest":
        startingView = "contest";
        break;
    default:
    case "past-contests-list":
        startingView = "past-contests-list";
        break;
    case "all-contests-list":
        startingView = "all-contests-list";
        break;
}

let contestId = contestIdAttribute ? parseInt(contestIdAttribute) : null;

console.log(
    `Kiwanis Voting App is starting. Page basename: ${pageBaseNameAttribute}. ` +
    `Starting view: ${startingView}. ` +
    `ContestId: ${contestIdAttribute}`
);


ReactDOM.render(
  <React.StrictMode>
    <div className="kiwanis-voting-app">
      <App pageBaseName={pageBaseName} startingView={startingView} contestId={contestId}/>
    </div>
  </React.StrictMode>,
  document.getElementById('voting-app-public-ui')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

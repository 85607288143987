import urljoin from "url-join";

export default class RoutingConstants {
  public static HOME: string = '/';

  /* All routes exept of "HOME" should have common prefix "/ki-va/" to make it easier to define
     URL rewrite rules on web server where the app will be hosted
  */
  public static LOGIN: string = '/ki-va/login';
  public static LOGIN_WITH_RETURN_URL: string = '/ki-va/login/:returnUrl';

  public static CONTEST_LIST: string = '/ki-va/contests/:page';
  public static PAST_CONTEST_LIST: string = '/ki-va/past-contests/:page';

  public static ENTRY_LIST: string = '/ki-va/contest/:contestId/:page/:sorting?';
  public static ENTRY_DETAILS: string = '/ki-va/entry-details/:contestId/:page/:entryId/:sorting?';

  public static CREATE_ENTRY: string = '/ki-va/entry-create/:contestId/:page/:sorting';
  public static EDIT_ENTRY: string = '/ki-va/entry-edit/:contestId/:page/:entryId/:sorting';

  public static PROFILE: string = '/ki-va/profile/:contestId/:page/:sorting';
  public static SUBMITTER_ENTRIES: string = '/ki-va/submitter-entries/:contestId/:page/:sorting';
  public static EDIT_ENTRY_AFTER_WIN: string = '/ki-va/entry-edit-after-win/:contestId/:page/:entryId/:sorting';

  public static NO_PERMITTED: string = '/ki-va/no-permitted';
  public static CONTEST_DOES_NOT_EXIST: string = '/ki-va/contest-does-not-exist';
  public static ENTRY_DOES_NOT_EXIST: string = '/ki-va/entry-does-not-exist/:contestId/:page/:sorting';

  public static buildEntryNotExistUrl(contestId: number, page: number, sorting: string): string {
    return `/ki-va/entry-does-not-exist/${contestId}/${page}/${sorting}`;
  };

  public static buildContestListUrl(page: number): string {
    return `/ki-va/contests/${page}`;
  };

  public static buildPastContestListUrl(page: number): string {
    return `/ki-va/past-contests/${page}`;
  };

  public static buildEntryListUrl(contestId: number, page: number, sorting: string | null): string {
    if (sorting) {
      return `/ki-va/contest/${contestId}/${page}/${sorting}`;
    } else {
      return `/ki-va/contest/${contestId}/${page}`;
    }
  };

  public static buildEntryDetailsUrl(contestId: number, page: number, entryId: number, sorting: string): string {
    return `/ki-va/entry-details/${contestId}/${page}/${entryId}/${sorting}`;
  };

  public static buildEntryCreateUrl(contestId: number, page: number, sorting: string): string {
    return `/ki-va/entry-create/${contestId}/${page}/${sorting}`;
  };

  public static buildEntryEditUrl(contestId: number, page: number, entryId: number, sorting: string): string {
    return `/ki-va/entry-edit/${contestId}/${page}/${entryId}/${sorting}`;
  };

  public static buildProfileUrl(contestId: number, page: number, sorting: string): string {
    return `/ki-va/profile/${contestId}/${page}/${sorting}`;
  };

  public static buildSubmitterEntriesUrl(contestId: number, page: number, sorting: string): string {
    return `/ki-va/submitter-entries/${contestId}/${page}/${sorting}`;
  };

  public static buildWinnerEntryEditUrl(contestId: number, page: number, entryId: number, sorting: string): string {
    return `/ki-va/entry-edit-after-win/${contestId}/${page}/${entryId}/${sorting}`;
  };

  public static buildNoPermittedUrl(): string {
    const rootElement = document.getElementById('voting-app-public-ui');
    const pageBaseNameAttribute = rootElement === null ? null : rootElement.getAttribute("data-page-location");

    return urljoin(pageBaseNameAttribute ?? '', '/ki-va/no-permitted');
  };

  public static buildLoginRedirectUrl(redirectUrl: string | null): string {
    if (!redirectUrl) {
      const rootElement = document.getElementById('voting-app-public-ui');
      const pageBaseNameAttribute = rootElement === null ? null : rootElement.getAttribute("data-page-location");

      return urljoin(pageBaseNameAttribute ?? '', '/ki-va/login');
    }

    return `/ki-va/login/${btoa(redirectUrl)}`;
  };
}
import { faCheckCircle, faCloudDownloadAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo } from 'react';
import { Spinner } from "react-bootstrap-v5";
import { useDropzone } from 'react-dropzone';
import { EntryPhoto } from "../../../api/api-interfaces/entry/add-or-edit-entry/EntryPhoto";
import { KIWANIS_BLUE_COLOR, KIWANIS_GOLD_COLOR } from "../../../constants/Constants";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  deleteFileIcon,
  img,
  mainPhotoStyle,
  rejectStyle,
  thumb,
  thumbInner,
  thumbsContainer
} from "../../../styles/ImageSmallStyles";
import { VerticalSpaceSize } from "../vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../vertical-space/VerticalSpace";
import { IImageUploadProps } from "./IImageUploadProps";
import { faVerticalAlign } from "../../../styles/IconStyles";

const ImageUpload: React.FC<IImageUploadProps> = ({photos, onDrop, onRemoveFile, onSelectMainPhoto, isImageLoading}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg',
    onDrop: onDrop
  });

  const style: any = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = photos.map((photo: EntryPhoto, index: number) => (
    <div style={thumb} key={index}>
      <div style={thumbInner} onClick={() => onSelectMainPhoto(index)}>
        <img style={img} src={photo.url ? photo.url : URL.createObjectURL(photo.imageFile)} alt={'entry img'}/>
      </div>
      <FontAwesomeIcon style={deleteFileIcon} icon={faWindowClose} color={KIWANIS_BLUE_COLOR}
                       onClick={() => onRemoveFile(index)}
      />
      {
        photo.isDefault &&
        <FontAwesomeIcon style={mainPhotoStyle} icon={faCheckCircle} color={KIWANIS_GOLD_COLOR}/>
      }
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    photos.forEach((photo: EntryPhoto) => URL.revokeObjectURL(photo.url ?? ''));
  }, [photos]);

  return (
    <div>
      <div {...getRootProps({style})}>

        {
          isImageLoading
            ? <div className='my-3'><Spinner animation="border" variant="primary"/></div>
            : <>
              <input name='Photos' {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudDownloadAlt} color={KIWANIS_BLUE_COLOR} size={"2x"} style={faVerticalAlign}/>
              <p>Drag & Drop files here or click to select file(s)</p>
            </>
        }

      </div>
      {
        photos.length > 0 &&
        <div className='my-1'>
          <VerticalSpace size={VerticalSpaceSize.small}/>
          <strong>Please click to select main photo</strong>
        </div>
      }
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
};

export default ImageUpload;
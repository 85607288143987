import React, { Component } from "react";
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { Col, Row } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import { Link, Redirect } from "react-router-dom";
import Api from "../../api/Api";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { IContestListState } from "./IContestListState";
import { IContestListProps } from "./IContestListProps";
import { IGetContestsPageRequest } from "../../api/api-interfaces/contest/contest-list/IGetContestsPageRequest";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";

class ContestList extends Component<IContestListProps, IContestListState> {
  listener: any;

  constructor(props: IContestListProps) {
    super(props);

    this.state = {
      contests: null,
      page: 1,
      totalPages: 1,
      itemsPerPage: 30,

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
      redirect: null,
    };
  }

  render() {
    if (this.state.redirect !== null) {
      const redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    return (
      <ContainerPageSize>
        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <ul>
                {
                  this.state.contests && this.state.contests.length > 0
                    ? this.state.contests.map((contest) => {
                      return (
                        <li key={contest.id} className='text-break my-1'>
                          <Link to={RoutingConstants.buildEntryListUrl(contest.id, 1, null)}
                                target="_blank">{contest.name}
                          </Link>
                        </li>
                      );
                    })
                    : <div className='text-center m-5'>No contests</div>
                }
              </ul>

              {
                this.state.totalPages > 1 &&
                <Row id='kva-contest-list-pager'>
                  <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                    <Pagination {...bootstrap5PaginationPreset}
                                current={this.state.page}
                                total={this.state.totalPages}
                                onPageChange={(page: number) => this.onContestPageChange(page)}
                    />
                  </Col>
                </Row>
              }
            </>
        }
      </ContainerPageSize>
    );
  }

  async componentDidMount() {
    let page = this.props.page;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const itemsPerPageParam = params.get('itemsPerPage');
    let itemsPerPage = itemsPerPageParam ? parseInt(itemsPerPageParam) : undefined;

    if (itemsPerPage) {
      await this.getContestList(page, itemsPerPage);
    } else {
      await this.getContestList(page, this.state.itemsPerPage);
    }

    this.listener = this.props.history.listen((location, action) => {
      if (action === "PUSH") {
        this.scrollToPager();
      }
    });
  }


  componentWillUnmount() {
    this.listener();
  }


  async componentDidUpdate(prevProps: Readonly<IContestListProps>) {
    if (this.props.page !== prevProps.page) {
      let state = {...this.state};
      state.isLoading = true;
      state.redirect = null;
      this.setState(state);

      await this.getContestList(this.props.page, this.state.itemsPerPage);
    }
  }

  private async getContestList(page: number, itemsPerPage: number) {
    let request: IGetContestsPageRequest = {
      page: page,
      itemsPerPage: itemsPerPage
    };

    try {
      let response = await Api.getContestsPage(request);

      this.setState({
        contests: response.contests,
        page: response.pager.page,
        itemsPerPage: itemsPerPage,
        totalPages: response.pager.totalPages,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get contest list from the server', err)
      );
    }
  }

  private async onContestPageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({
        page: page,
        redirect: RoutingConstants.buildContestListUrl(page)
      });
    }
  }

  private scrollToPager() {
    setTimeout(() => {
      scrollToIdHelper(`kva-contest-list-pager`);
    }, 500);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        const customId = "contest-list-custom-id";

        toast.error(render, {toastId: customId});
      }
    }
  }
}

export default ContestList;
export default class ContestOrEntryDoesNotExistError extends Error {
  public cause: any;

  constructor(cause?: any) {
    super('Access to the target resource is no longer available');

    Object.setPrototypeOf(this, new.target.prototype);

    this.cause = cause;
  }
}
import { ICodeAndNameInfo } from "../api/api-interfaces/common/ICodeAndNameInfo";

export const KIWANIS_BLUE_COLOR: string = '#003874';
export const KIWANIS_GOLD_COLOR: string = '#B49759';

export const EMAIL_REGEX: RegExp = new RegExp(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/);

export const DATE_TIME_ISO_FORMAT: string = "YYYY-MM-DDTHH:mm:ss";
export const DATE_PICKER_FORMAT: string = 'MM/dd/yyyy h:mm aa';
export const TIME_PICKER_FORMAT: string = 'h:mm aa';
export const MOMENT_DATE_TIME_FORMAT: string = 'MM/DD/YYYY h:mm a';

export const CHECK_ERROR_MESSAGE: string = 'Please check validation errors!';

export const OK: string = "ok";
export const UNKNOWN_OR_EXPIRED_CODE: string = "UnknownOrExpiredCode";
export const SIGN_IN_TEMPORARILY_BLOCKED_FOR_USER: string = "SignInTemporarilyBlockedForUser";

export const DEFAULT: string = "DEFAULT";

// -------------------------------------- CODES & OPTIONS -------------------------------------- //

export const CUSTOM_FIELD_CODES = {
  Text: 'TEXT',
  TextArea: 'AREA',
  RadioButton: 'RBTN',
  DropDownList: 'DRDW',
  Checkbox: 'CHBX',
  DatePicker: 'DATE'
};

export const ENTRY_SORTING = {
  Random: 'Random',
  Votes: 'Votes',
  VotesDescending: 'VotesDescending',
  Views: 'Views',
  ViewsDescending: 'ViewsDescending',
  SubmitDate: 'SubmitDate',
  SubmitDateDescending: 'SubmitDateDescending',
  Alphabetically: 'Alphabetically',
  AlphabeticallyDescending: 'AlphabeticallyDescending',
};

export const ENTRY_SORTING_OPTIONS: ICodeAndNameInfo[] = [
  {code: ENTRY_SORTING.Random, name: 'Random'},
  {code: ENTRY_SORTING.Votes, name: 'Least voted'},
  {code: ENTRY_SORTING.VotesDescending, name: 'Most voted'},
  {code: ENTRY_SORTING.Views, name: 'Least views'},
  {code: ENTRY_SORTING.ViewsDescending, name: 'Most views'},
  {code: ENTRY_SORTING.SubmitDate, name: 'Oldest'},
  {code: ENTRY_SORTING.SubmitDateDescending, name: 'Newest'},
  {code: ENTRY_SORTING.Alphabetically, name: 'Alphabetically A-Z'},
  {code: ENTRY_SORTING.AlphabeticallyDescending, name: 'Alphabetically Z-A'},
];
import React, { Component } from 'react';
import { IContestDetailsProps } from "./IContestDetailsProps";
import Api from "../../../api/Api";
import { Col, Container, Row } from "react-bootstrap-v5";
import VerticalSpace from "../vertical-space/VerticalSpace";
import { VerticalSpaceSize } from "../vertical-space/IVerticalSpaceProps";
import { IContestDetailsData } from "../../../api/api-interfaces/common/IContestDetailsData";

export class ContestDetails extends Component<IContestDetailsProps> {
  render() {
    let contestName = this.props.contestDetailsData?.contestName
    let contestDescription = this.props.contestDetailsData?.contestDescription
    let rulesAndPrizesHtml = this.props.contestDetailsData?.rulesAndPrizesHtml

    return (
      <Container fluid>
        <Row>
          <Col>
            <h1 className='text-break headline'>{contestName}</h1>
          </Col>
        </Row>

        {
          contestDescription &&
          <>
            <Row className='text-break'>
              <Col>
                <div className='text-break m-0' style={{whiteSpace: "pre-line"}}>{contestDescription}</div>
              </Col>
            </Row>
            <VerticalSpace size={VerticalSpaceSize.small}/>
          </>
        }

        {
          rulesAndPrizesHtml &&
          <>
            <Row className='text-break'>
              <Col>
                <div className='dynamic-content-div' dangerouslySetInnerHTML={{__html: rulesAndPrizesHtml ?? ''}}/>
              </Col>
            </Row>
            <VerticalSpace size={VerticalSpaceSize.small}/>
          </>
        }
      </Container>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;

    if (contestId && !this.props.contestDetailsData) {
      await this.getContestDetails(contestId);
    }
  }

  private async getContestDetails(contestId: number) {
    try {
      let response = await Api.getContestDetails(contestId);

      let data: IContestDetailsData = {
        contestName: response.contestName,
        contestDescription: response.contestDescription,
        rulesAndPrizesHtml: response.rulesAndPrizesHtml
      }

      this.props.dataUpdateCallback(data)
    } catch (err) {

    }
  }
}

export default ContestDetails;
